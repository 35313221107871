<template>
  <div class="flex flex-col h-full space-y-5">
    <template v-if="groupedOffers.length">
      <funder-card
        v-for="funder in groupedOffers"
        :key="funder.id"
        :grouped-offers="funder"
        :sole-funder="groupedOffers.length === 1 && !hasPlacementStage"
        is-row-clickable
        is-closing
      />
    </template>
    <template v-else>
      <offers-empty :is-self-funding="isClientFunded" is-closing />
    </template>
  </div>
</template>
<script setup lang="ts">
import type { IOffer } from "@/models/funders";
import { computed } from "vue";
import { useStore } from "vuex";
import FunderCard from "@/components/deals/workflow/OfferStage/WorkflowLive/FunderCard.vue";
import OffersEmpty from "@/views/deals/components/OffersEmpty.vue";
import { useDeals } from "@/hooks/deals";
import { useActiveWorkflowTemplate } from "@/hooks/workflow";
import { offersGroupedByFunder } from "@/helpers/offers";

const { getters } = useStore();
const { isClientFunded } = useDeals();

const offers = computed<IOffer[]>(() => getters["applications/offers"]);

const { hasStageTypeComputed } = useActiveWorkflowTemplate();

const groupedOffers = computed(() => {
  return offersGroupedByFunder(offers.value, { status: 1 });
});

const hasPlacementStage = hasStageTypeComputed("placement");
</script>
