import type {
  GroupedOffer,
  OfferTemplate,
  OfferTemplateBase
} from "@/models/offers";
import { OfferProductType } from "@/enums/offers";
import store from "@/store";
import type { GroupedOfferSearchParams, IOffer } from "@/models/funders";
import { PRODUCT_TYPE_REVERSE_MAPPING } from "./constants/workflow";
import type { ProductType } from "@/models/options";

export const getMissingOfferTemplates = (
  offerTemplates: OfferTemplate[],
  funder?: { product_types: ProductType[] } | null
) => {
  const fallbackTemplates = (store.getters[
    "options/fallBackOfferTemplatesOptions"
  ] ?? []) as OfferTemplateBase[];
  const supportedProductTypes = funder?.product_types;
  const templateProductTypes = offerTemplates.map(
    (template) => template.product
  );

  const notIncludedProductTypes = Object.values(OfferProductType).filter(
    (productType) => {
      return (
        !templateProductTypes.includes(productType) &&
        (!funder ||
          supportedProductTypes?.includes(
            Number(PRODUCT_TYPE_REVERSE_MAPPING[productType]) as ProductType
          ))
      );
    }
  );

  return notIncludedProductTypes.reduce<OfferTemplateBase[]>(
    (acc, productType) => {
      const fallbackTemplate = fallbackTemplates.find(
        (template) => template.product === productType
      );
      if (fallbackTemplate) {
        acc.push(fallbackTemplate);
      }
      return acc;
    },
    []
  );
};

export const offersGroupedByFunder = (
  offers: IOffer[],
  filterParams?: GroupedOfferSearchParams
): GroupedOffer[] => {
  const { status, origin_template_name } = filterParams ?? {};
  const groupedOffers: Record<string, GroupedOffer> = {};

  for (const offer of offers) {
    const funderId = offer.funder?.id;

    // Skip offers without funder or with non-matching status
    if (!funderId || (status !== undefined && offer.status !== status)) {
      continue;
    }

    const productId = offer.details.offer_template_id ?? "";
    const recordId = funderId + productId;

    // Initialize group if it doesn't exist
    if (!groupedOffers[recordId]) {
      groupedOffers[recordId] = {
        id: funderId,
        funder: offer.funder,
        offer_details: []
      };
    }

    // Only add offer to the group if template name matches (when filter is applied)
    if (
      !origin_template_name ||
      offer.details.offer_template.name
        .toLowerCase()
        .includes(origin_template_name.toLowerCase())
    ) {
      groupedOffers[recordId].offer_details.push(offer);
    }
  }

  return Object.values(groupedOffers);
};
